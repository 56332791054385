(function() {
  var dynbox, no_dynbox, obj, second_ajax;

  global.flash_alert = function(msg) {
    return $.notify({
      message: msg
    }, {
      type: 'warning',
      width: '600px',
      placement: {
        from: "top",
        align: "center"
      },
      offset: {
        x: 20,
        y: 4
      }
    });
  };

  global.flash_notice = function(msg) {
    return $.notify({
      message: msg
    }, {
      type: 'success',
      width: '600px',
      placement: {
        from: "top",
        align: "center"
      },
      offset: {
        x: 20,
        y: 4
      }
    });
  };

  global.permanent_flash_notice = function(msg, url) {
    return $.notify({
      message: msg,
      url: url
    }, {
      type: 'warning',
      width: '600px',
      placement: {
        from: "top",
        align: "center"
      },
      offset: {
        x: 20,
        y: 4
      },
      delay: 599999
    });
  };

  global.sticky_notice = function(msg, url) {
    return $.notify({
      message: msg,
      url: url
    }, {
      type: 'warning',
      width: '600px',
      placement: {
        from: "top",
        align: "center"
      },
      offset: {
        x: 20,
        y: 4
      },
      delay: 599999
    });
  };

  $(document).on('turbolinks:load', function() {
    if ($('body').data('env') !== 'test') {
      if ($('.js-alert-success').length) {
        flash_notice($('.js-alert-success').html());
        $('.js-alert-success').remove();
      }
      if ($('.js-alert-danger').length) {
        flash_alert($('.js-alert-danger').html());
        return $('.js-alert-danger').remove();
      }
    }
  });

  second_ajax = false;

  $(document).on('ajax:send', function(ev) {
    return $('body').addClass('ajax-progress');
  });

  $(document).on('ajax:complete', function(ev) {
    if (!second_ajax) {
      return $('body').removeClass('ajax-progress');
    }
  });

  $(document).on('ajax:error', function(ev) {
    return permanent_flash_notice(ev.detail[2].responseText);
  });

  $(document).on('ajax:before', function(ev) {
    if (!$(ev.target).attr('data-type')) {
      return $(ev.target).attr('data-type', 'html');
    }
  });

  $(document).on('ajax:before', function(ev) {
    var el;
    el = $(ev.target);
    if (!el.is('form')) {
      return;
    }
    return el.find(':submit').attr('data-disable', true);
  });

  $(document).on('ajaxStart', function(ev) {
    return $('body').addClass('ajax-progress');
  });

  $(document).on('ajaxStop', function(ev) {
    if (!second_ajax) {
      return $('body').removeClass('ajax-progress');
    }
  });

  obj = function(el, command) {
    var id, object;
    id = el.data(command);
    if (!id.match(/^[#\.]/)) {
      id = "#" + id;
    }
    object = $(id);
    if (object.length === 0) {
      console.log('Cannot find $("' + id + '") => Declared at data-' + command + ': ' + id);
    }
    return object;
  };

  no_dynbox = function(ev) {
    return dynbox(ev).length === 0;
  };

  dynbox = function(ev) {
    return $(ev.target).closest('.js-crud-dynbox');
  };

  $(document).on('mousedown', 'a[data-hide]', function(ev) {
    var el;
    if (no_dynbox(ev)) {
      return;
    }
    el = $(ev.target);
    $(el).closest('.js-crud-dynbox').collapse('hide');
    $('body').removeClass('ajax-progress');
    return second_ajax = false;
  });

  $(document).on('click', 'a[data-hide]', function(ev) {
    if (no_dynbox(ev)) {
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  });

  $(document).on('mousedown', 'input[type="submit"][data-submit],input[type="submit"][data-submit-reload]', function(ev) {
    var btn, form;
    if (no_dynbox(ev)) {
      return;
    }
    btn = $(ev.target);
    form = btn.closest('form');
    $('body').addClass('ajax-progress');
    return $.ajax({
      type: form.prop('method'),
      url: form.prop('action'),
      data: form.serialize()
    }).done(function(data, textStatus, jqXHR) {
      if (btn.data('submit-reload')) {
        obj(btn, 'submit-reload').trigger('reload');
      }
      return dynbox(ev).collapse('hide');
    }).fail(function(jqXHR, textStatus, errorThrown) {
      return flash_alert(jqXHR.responseText);
    }).always(function() {
      $('body').removeClass('ajax-progress');
      return second_ajax = false;
    });
  });

  $(document).on('click', 'input[type="submit"][data-submit],input[type="submit"][data-submit-reload]', function(ev) {
    if (no_dynbox(ev)) {
      return;
    }
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  });

  $(document).on('ajax:success', function(ev, request) {
    var append_class, content, el, tr, tr_content;
    el = $(ev.target);
    if (request) {
      content = request;
    } else {
      content = ev.detail[2].responseText;
    }
    if (el.data('trigger')) {
      $(document).trigger(el.data('trigger'));
    }
    if (el.data('reset')) {
      el.trigger('reset');
    }
    if (el.data('submit')) {
      Rails.fire(document.querySelector(el.data('submit')), 'submit');
    }
    if (el.data('reload')) {
      $(el.data('reload')).trigger('reload');
    }
    if (el.data('delayed-reload')) {
      setTimeout((function() {
        return $(el.data('delayed-reload')).trigger('reload');
      }), 500);
    }
    if (el.data('remove')) {
      $(el.data('remove')).remove();
    }
    if (el.data('clear')) {
      $(el.data('clear')).trigger('clear');
    }
    if (el.data('toggle')) {
      $(el.data('toggle')).collapse('toggle');
    }
    if (el.data('show')) {
      $(el.data('show')).collapse('show');
    }
    if (el.data('hide')) {
      $(el.data('hide')).collapse('hide');
    }
    if (el.data('update')) {
      obj(el, 'update').trigger('update', content);
    }
    if (el.data('append')) {
      tr = $(el.data('append'));
      append_class = tr.attr('id') + '-append';
      if ($('.' + append_class).length) {
        return $('.' + append_class).trigger('remove');
      }
      tr_content = "<tr class='js-remove " + append_class + "'><td colspan='" + tr.find('td').size() + "'>" + '<div class="js-remove" style="display: none">' + content + "</div></td></tr>";
      tr.after(tr_content).trigger('turbolinks:load');
      $('div.js-remove').slideDown();
    }
    if (el.data('reload') || el.data('delayed-reload')) {
      second_ajax = true;
      return false;
    }
    $(document).trigger('ajax:complete', ev);
    if (el.attr('href') === '#') {
      return false;
    }
  });

  $(document).on('reload', function(ev) {
    var target, url;
    target = $(ev.target);
    url = target.data('url');
    if (url == null) {
      return false;
    }
    if (url === '#') {
      return false;
    }
    $('body').addClass('ajax-progress');
    return $.get({
      url: url,
      data_type: 'html'
    }).done(function(data) {
      $(target).html(data).trigger('turbolinks:load');
      $('body').removeClass('ajax-progress');
      return second_ajax = false;
    });
  });

  $(document).on('mousedown', '.js-trigger-remove', function(ev) {
    return $('div.js-remove').slideUp(function() {
      return $('.js-remove').remove();
    });
  });

  $(document).on('click', '.js-trigger-remove', function(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  });

  $(document).on('remove', function(ev) {
    var target;
    target = $(ev.target);
    if (!target.length) {
      return;
    }
    return target.slideUp(function() {
      return target.remove();
    });
  });

  $(document).on('clear', function(ev) {
    var target;
    target = $(ev.target);
    if (!target.length) {
      return;
    }
    if (target.hasClass('collapse')) {
      target.addClass('js-collapse-autoclean').collapse('hide');
    } else {
      target.slideUp(function() {
        return target.html('');
      });
    }
    return $('body').removeClass('ajax-progress');
  });

  $(document).on('update', function(ev, data) {
    var hide, target;
    target = $(ev.target);
    if (!target.length) {
      return;
    }
    if (data.substr(0, 10) === 'Turbolinks') {
      second_ajax = true;
      return;
    }
    hide = target.is(':visible');
    if (target.data('nohide') === true) {
      hide = false;
    }
    if (hide) {
      return target.collapse('hide');
    } else {
      if (!target.hasClass('js-crud-dynbox')) {
        target.addClass('js-crud-dynbox');
      }
      if (target.hasClass('collapse')) {
        return target.html(data).trigger('turbolinks:load').collapse('show');
      } else {
        target.show();
        return target.html(data).trigger('turbolinks:load');
      }
    }
  });

  $(document).on('hidden.bs.collapse', '.collapse', function(ev) {
    var target;
    target = $(ev.target);
    if (target.hasClass('js-collapse-autoclean')) {
      return target.html('');
    }
  });

}).call(this);
