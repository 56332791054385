/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

import Rails from '@rails/ujs';
Rails.start();
global.Rails = Rails;

import Turbolinks from "turbolinks";
Turbolinks.start();

import 'bootstrap'
import '../javascripts/vendor/bootstrap-notify.min.js'

import 'tablesorter'
import 'jquery-validation'

import flatpickr from 'flatpickr'

import 'select2/dist/js/select2.full'
import '../javascripts/vendor/maximize-select2-height.min'

import '../javascripts/application.js'
import '../javascripts/crud.js'
import '../javascripts/controllers'

// Import Trix and Action Text
require("trix")
require("@rails/actiontext")

// Style
import 'select2/dist/css/select2.css';
import 'select2-theme-bootstrap4/dist/select2-bootstrap.min.css';
import 'tablesorter/dist/css/theme.default.min.css';
import 'flatpickr/dist/flatpickr.css';
import '../stylesheets/actiontext.css';
import '../stylesheets/application.sass';
