import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.obfuscateEmail()
  }

  obfuscateEmail() {
    const emailElement = this.element
    const obfuscatedEmail = 'harid' + ' at ' + 'tugi.edu.ee'
    emailElement.textContent = obfuscatedEmail
  }
}
