(function() {
  var clock_interval, clock_ping, clock_reset;

  $.validator.addMethod('nr', (function(value, element) {
    return this.optional(element) || /^[\d\.\,]+$/.test(value);
  }), 'Please enter a valid number.');

  $.validator.addMethod('mspassword', (function(value, element) {
    return this.optional(element) || /^[a-zA-Z0-9#!@$%^&*()\-_+={}[\]|\\:;"'<>,.?\/ ]+$/.test(value);
  }), function(para, element) {
    var chrs, notallowed;
    chrs = $(element).val();
    notallowed = [];
    chrs.split('').forEach(function(chr, index, array) {
      if (/^[a-zA-Z0-9#!@$%^&*()\-_+={}[\]|\\:;"'<>,.?\/ ]+$/.test(chr)) {
        return;
      }
      if (notallowed.includes(chr)) {
        return;
      }
      return notallowed.push(chr);
    });
    notallowed = notallowed.sort().join(' ');
    return $.validator.format(para, notallowed);
  });

  $(document).on('turbolinks:load', function() {
    return $('form').each(function() {
      return $(this).validate({
        errorClass: 'error is-invalid',
        errorElement: 'div',
        highlight: function(element, errorClass, validClass) {
          $(element).addClass(errorClass).removeClass(validClass);
          return $(element).next('div').addClass('invalid-feedback');
        },
        unhighlight: function(element, errorClass, validClass) {
          $(element).removeClass(errorClass).addClass(validClass);
          return $(element.form).next('div').removeClass('invalid-feedback');
        },
        errorPlacement: function(error, element) {
          error.addClass('invalid-feedback');
          return error.insertAfter(element);
        }
      });
    });
  });

  $(document).on('mousedown', '.js-toggle', function(ev) {
    var el, target;
    target = $(this);
    el = $(target.data('target'));
    if (el.length) {
      if (el.is(':visible')) {
        return el.hide();
      } else {
        if (target.data('selfdestroy')) {
          target.hide();
        }
        return el.show();
      }
    }
  });

  $(document).on('click', '.js-toggle', function(ev) {
    ev.preventDefault();
    return ev.stopPropagation();
  });

  $(function() {
    return $('[data-toggle=tooltip]').tooltip({
      html: true
    });
  });

  $(function() {
    $(".search_field").select2({
      placeholder: "Select",
      allowClear: true,
      dropdownAutoWidth: true,
      maximumInputLength: 10
    });
  });

  $(function() {
    $(".typeahead").select2({
      placeholder: "Select",
      allowClear: true,
      dropdownAutoWidth: true,
      maximumInputLength: 10,
      width: 'resolve'
    });
  });

  $(document).on('turbolinks:load', function() {
    if (!window.selected_ad_users) {
      window.selected_ad_users = {};
    }
    $('#js_select_all').on('click', function() {
      var checked;
      checked = this.checked;
      return $('.js-mass-select').each(function() {
        $(this).prop('checked', checked);
        window.selected_ad_users[this.value] = checked;
        return true;
      });
    });
    $('.js-mass-select').on('click', function() {
      window.selected_ad_users[this.value] = this.checked;
      return true;
    });
    return $('#new_ad_update').on('submit', function() {
      var ids;
      ids = Object.keys(window.selected_ad_users).filter(function(id) {
        return window.selected_ad_users[id];
      });
      $('input[name="selected_ids"]', this).prop('value', ids);
      return true;
    });
  });

  $(document).on('turbolinks:load', function() {
    if ($('.remember_me').length) {
      return $('.remember_me').on('change', function() {
        $.ajax({
          url: '/en/remember_cookie',
          type: $(this).is(':checked') ? 'POST' : 'DELETE',
          headers: {
            'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
          }
        });
        return $('.remember_me').prop('checked', $(this).is(':checked'));
      });
    }
  });

  jQuery(function() {
    var check_entitlement;
    return check_entitlement = function(check_box, name_field) {
      if (check_box.is(':checked')) {
        name_field.prop("disabled", false);
      } else {
        name_field.prop("disabled", true);
      }
    };
  });

  jQuery(function() {
    $('form').on('click', '.remove_fields', function(event) {
      $(this).prev('input[type=hidden]').val('1');
      $(this).closest('fieldset').hide();
      return event.preventDefault();
    });
    return $('form').on('click', '.add_fields', function(event) {
      var regexp, time;
      time = new Date().getTime();
      regexp = new RegExp($(this).data('id'), 'g');
      $(this).before($(this).data('fields').replace(regexp, time));
      return event.preventDefault();
    });
  });

  jQuery(function() {
    $(".person_emails").on('click', ".primarybutton", function() {
      $(this).parents(".person_emails").find('input.primarybutton').prop('checked', false);
      return $(this).prop('checked', true);
    });
    return $(".person_phone_numbers").on('click', ".primarybutton", function() {
      $(this).parents(".person_phone_numbers").find('input.primarybutton').prop('checked', false);
      return $(this).prop('checked', true);
    });
  });

  window.check_option = function(element, val) {
    var e;
    e = $(element);
    if (val === "") {
      $(e).closest("form").find(".person_fields :input").prop("disabled", false);
      $(e).closest("form").find(".person_fields").show();
    } else {
      $(e).closest("form").find(".person_fields :input").prop("disabled", true);
      $(e).closest("form").find(".person_fields").hide();
    }
  };

  this.flash_data = function(data) {
    if (data.notice) {
      return flash_notice(data.notice);
    }
    if (data.alert) {
      return flash_alert(data.alert);
    }
  };

  this.flash_alert = function(msg) {
    return $.notify({
      message: msg
    }, {
      type: 'warning',
      placement: {
        from: "top",
        align: "center"
      },
      offset: {
        x: 20,
        y: 4
      }
    });
  };

  this.flash_notice = function(msg) {
    return $.notify({
      message: msg
    }, {
      type: 'success',
      placement: {
        from: "top",
        align: "center"
      },
      offset: {
        x: 20,
        y: 4
      }
    });
  };

  this.sticky_notice = function(msg, url) {
    return $.notify({
      message: msg,
      url: url
    }, {
      type: 'warning',
      placement: {
        from: "top",
        align: "center"
      },
      offset: {
        x: 20,
        y: 4
      },
      delay: 599999
    });
  };

  $(document).on('turbolinks:load', function() {
    if ($('body').data('env') !== 'test') {
      if ($('.js-alert-success').length) {
        flash_notice($('.js-alert-success').html());
        $('.js-alert-success').remove();
      }
      if ($('.js-alert-danger').length) {
        flash_alert($('.js-alert-danger').html());
        $('.js-alert-danger').remove();
      }
      if ($('.js-sticky-alert-success').length) {
        sticky_notice($('.js-sticky-alert-success').html());
        $('.js-sticky-alert-success').remove();
      }
      if ($('.alert.alert-danger').length) {
        flash_alert($('.alert.alert-danger li, .alert.alert-danger p').html());
        return $('.alert.alert-danger').remove();
      }
    }
  });

  $(document).on('turbolinks:before-cache', function() {
    return $('.js-select2, .js-select2-data, .js-select2-tags, .js-select2-whodunnit').select2('destroy');
  });

  $(document).on('turbolinks:load cocoon:after-insert', function() {
    $('.js-select2').each(function() {
      var $this, ref;
      $this = $(this);
      return $this.select2({
        theme: 'bootstrap',
        language: $('html').attr('lang'),
        placeholder: function() {
          return $(this).data('placeholder');
        },
        tags: (ref = $this.hasClass('js-select2-tags')) != null ? ref : {
          "true": false
        },
        width: '100%'
      });
    });
    $('.js-select2-tags').each(function() {
      var $this;
      $this = $(this);
      return $this.select2({
        language: $('html').attr('lang'),
        placeholder: function() {
          return $(this).data('placeholder');
        },
        tags: true,
        width: '100%'
      }).maximizeSelect2Height();
    });
    return $('.js-select2-whodunnit').each(function() {
      var $this;
      $this = $(this);
      return $this.select2({
        theme: 'bootstrap',
        language: $('html').attr('lang'),
        minimumInputLength: 3,
        width: '100%',
        placeholder: function() {
          return $(this).data('placeholder');
        },
        ajax: {
          url: '/' + $('html').attr('lang') + '/whodunnit_users',
          cache: true,
          dataType: 'json',
          delay: 250,
          processResults: function(whodunnit_users) {
            return {
              results: whodunnit_users.map(function(whodunnit) {
                return {
                  id: whodunnit.id,
                  text: whodunnit.text
                };
              })
            };
          }
        }
      });
    });
  });

  $.tablesorter.addParser({
    id: 'date-with-time',
    is: function(s) {
      return false;
    },
    format: function(s) {
      var err;
      try {
        return s.substring(6, 10) + s.substring(3, 5) + s.substring(0, 2) + s.substring(10);
      } catch (error1) {
        err = error1;
        return '';
      }
    },
    type: 'text'
  });

  $(document).on('turbolinks:load js-tablesorter', function() {
    if ($('.js-tablesorter').length) {
      return $(".js-tablesorter").tablesorter({
        sortInitialOrder: 'desc',
        dateFormat: 'ddmmyyyy',
        textExtraction: function(node) {
          return $(node).text().replace(/[,]/g, '.').replace(/[ ]/g, '');
        }
      });
    }
  });

  $(document).on('turbolinks:before-cache', function() {
    if ($('.js-tablesorter').length) {
      return $(".js-tablesorter").trigger('destroy');
    }
  });

  $(document).on('turbolinks:load', function() {
    return flatpickr('.flatpickr-input', {
      locale: $('html').attr('lang'),
      dateFormat: 'd.m.Y',
      allowInput: true,
      wrap: true,
      nextArrow: "<i class='mdi mdi-chevron-right'></i>",
      prevArrow: "<i class='mdi mdi-chevron-left'></i>"
    });
  });

  $(document).on('click', '.js-clone', function(ev) {
    var target, targetDiv;
    ev.preventDefault();
    target = $(ev.target);
    targetDiv = $(target.data('target'));
    return targetDiv.clone().prependTo(target).attr("id", "new" + Math.random()).show();
  });

  $(document).on('turbolinks:load', function() {
    var form;
    if ($('.js-trigger-smartid').length) {
      form = $('.js-trigger-smartid');
      return setTimeout((function() {
        return form.trigger('submit.rails');
      }), 3000);
    }
  });

  $(document).on('turbolinks:load', function() {
    var form;
    if ($('.js-trigger-mobileid').length) {
      form = $('.js-trigger-mobileid');
      return setTimeout((function() {
        return form.trigger('submit.rails');
      }), 3000);
    }
  });

  $(document).on('change', '.js-checkbox-confirm', function(ev) {
    var target;
    target = $(this);
    if (target.is(':checked')) {
      return alert(target.data('confirm'));
    }
  });

  $(document).on('submit', function(ev) {
    return $('body').addClass('ajax-progress');
  });

  clock_interval = 0;

  clock_reset = function() {
    var alertStr, alert_shown, expiration, extendStr, extend_shown, inactivity_activated, sessionEndedStr, timeout;
    alertStr = $('.js-clock-reset').data('alert');
    extendStr = $('.js-clock-reset').data('extend');
    sessionEndedStr = $('.js-clock-reset').data('ended');
    timeout = $('.js-clock-reset').data('timeout');
    expiration = new Date();
    expiration.setSeconds(expiration.getSeconds() + timeout);
    expiration = Math.floor(expiration.getTime());
    clearInterval(clock_interval);
    alert_shown = false;
    extend_shown = false;
    inactivity_activated = false;
    return clock_interval = setInterval((function() {
      var diff, display, seconds, string;
      diff = expiration - new Date();
      seconds = Math.floor(diff / 1000);
      if (seconds > timeout / 5) {
        return;
      }
      if (seconds <= 0) {
        seconds = 0;
      }
      if (!alert_shown) {
        flash_alert(alertStr);
        alert_shown = true;
      }
      if (!inactivity_activated) {
        inactivity_activated = true;
        $(document).on('mousemove keydown', function(ev) {
          $(document).unbind('mousemove keydown');
          return clock_ping();
        });
      }
      display = new Date(0);
      display.setSeconds(seconds);
      string = display.toISOString().substr(14, 5);
      $('.js-clock').text(string);
      if (!extend_shown) {
        $('.js-clock-reset').text(extendStr).show();
        extend_shown = true;
      }
      if (seconds <= 0) {
        clearInterval(clock_interval);
        $('.js-clock-reset').text(sessionEndedStr);
        $('.js-clock-reset').unbind('click');
        return setTimeout((function() {
          return window.location.reload();
        }), 3000);
      }
    }), 1000);
  };

  $(document).on('turbolinks:load', function() {
    if ($('.js-clock').length) {
      return clock_reset();
    }
  });

  $(document).on('turbolinks:load', function() {
    if ($('.js-clock-reset').length) {
      return $('.js-clock-reset').on('click', function(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        return clock_ping();
      });
    }
  });

  clock_ping = function() {
    return $.ajax({
      url: '/en/remember_cookie',
      type: 'DELETE',
      headers: {
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
      }
    }).done(function(data) {
      clock_reset();
      $('.js-clock').text('');
      return $('.js-clock-reset').hide();
    });
  };

  $(document).on('turbolinks:load', function() {
    return $('input.custom-control-input[type=checkbox]').on('focusin focusout', function() {
      return $(this).parent('.custom-control').toggleClass('focus-visible');
    });
  });

}).call(this);
